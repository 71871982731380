import styled from 'styled-components'

import ADA from '../../images/currencies/ada.png'
import AUD from '../../images/currencies/aud.png'
import BNB from '../../images/currencies/bnb.png'
import BRL from '../../images/currencies/brl.png'
import BTC from '../../images/currencies/btc.png'
import CAD from '../../images/currencies/cad.png'
import CLP from '../../images/currencies/clp.png'
import DOGE from '../../images/currencies/doge.png'
import ETH from '../../images/currencies/eth.png'
import EUR from '../../images/currencies/eur.png'
import EURK from '../../images/currencies/eurk.png'
import GBP from '../../images/currencies/gbp.png'
import INR from '../../images/currencies/inr.png'
import JPY from '../../images/currencies/jpy.png'
import LTC from '../../images/currencies/ltc.png'
import NOK from '../../images/currencies/nok.png'
import NZD from '../../images/currencies/nzd.png'
import PEN from '../../images/currencies/pen.png'
import PLN from '../../images/currencies/pln.png'
import SEK from '../../images/currencies/sek.png'
import SOL from '../../images/currencies/sol.png'
import TRY from '../../images/currencies/try.png'
import USD from '../../images/currencies/usd.png'
import USDC from '../../images/currencies/usdc.png'
import USDT from '../../images/currencies/usdt.png'
import XRP from '../../images/currencies/xrp.png'

export const CurrencyIconWrapper = styled.span<{ width: string; height: string }>`
  display: inline-block;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 8px;

  &.card {
    border: 1px solid #ffffff;
  }

  &.circle {
    border-radius: 50%;
  }

  //prettier-ignore
  &.ADA {
    background-image: url("${ADA}");
  }
  //prettier-ignore
  &.AUD {
    background-image: url("${AUD}");
  }
  //prettier-ignore
  &.BNB {
    background-image: url("${BNB}");
  }
  //prettier-ignore
  &.BRL {
    background-image: url("${BRL}");
  }
  //prettier-ignore
  &.BTC {
    background-image: url("${BTC}");
  }
  //prettier-ignore
  &.CAD {
    background-image: url("${CAD}");
  }
  //prettier-ignore
  &.CLP {
    background-image: url("${CLP}");
  }
  //prettier-ignore
  &.SEK {
    background-image: url("${SEK}");
  }
  //prettier-ignore
  &.DOGE {
    background-image: url("${DOGE}");
  }
  //prettier-ignore
  &.ETH {
    background-image: url("${ETH}");
  }
  //prettier-ignore
  &.EUR {
    background-image: url("${EUR}");
  }
  //prettier-ignore
  &.GBP {
    background-image: url("${GBP}");
  }
  //prettier-ignore
  &.INR {
    background-image: url("${INR}");
  }
  //prettier-ignore
  &.JPY {
    background-image: url("${JPY}");
  }
  //prettier-ignore
  &.LTC {
    background-image: url("${LTC}");
  }
  //prettier-ignore
  &.NOK {
    background-image: url("${NOK}");
  }
  //prettier-ignore
  &.NZD {
    background-image: url("${NZD}");
  }
  //prettier-ignore
  &.PEN {
    background-image: url("${PEN}");
  }
  //prettier-ignore
  &.PLN {
    background-image: url("${PLN}");
  }
  //prettier-ignore
  &.SOL {
    background-image: url("${SOL}");
  }
  //prettier-ignore
  &.USD {
    background-image: url("${USD}");
  }
  //prettier-ignore
  &.USDC,
  &.USDC-ERC20,
  &.USDC-TRC20 {
    background-image: url("${USDC}");
  }
  //prettier-ignore
  &.USDT,
  &.USDT-ERC20,
  &.USDT-TRC20 {
    background-image: url("${USDT}");
  }
  //prettier-ignore
  &.EURK-ERC20 {
    background-image: url("${EURK}");
  }
  //prettier-ignore
  &.XRP {
    background-image: url("${XRP}");
  }
  //prettier-ignore
  &.TRY {
    background-image: url("${TRY}");
  }
`
