import { ReactNode, useContext, useEffect, useState } from 'react'

import cn from 'classnames'

import { Button } from 'mmfintech-portal-commons'
import { CurrencyIcon } from '../CurrencyIcon'
import { AccountCreateModal } from '../AccountCreateModal'
import { ChevronDownIcon, ChevronUpIcon } from '../../icons'
import {
  AccountButtonStyled,
  AccountListStyled,
  AccountStyled,
  AddButtonWrapper,
  SelectAccountError,
  SelectAccountLabel,
  SelectAccountStyled,
  SelectAccountWrapper
} from './SelectAccount.styled'

import { isOwnerOrAdministrator, selectCurrentUserRole, useAppSelector } from 'mmfintech-backend-api'
import { tr, useDropDownMenu, formatMoney, isValidArray, GlobalContext, isValidString } from 'mmfintech-commons'

import { AccountBalanceOut } from 'mmfintech-commons-types'

type SelectAccountProps = {
  staticDisplay?: boolean // if set, static account display will be presented
  accounts?: AccountBalanceOut[] // list of accounts to select from
  error?: string // error message (if needed)
  label?: ReactNode // label above the selection box
  showAddButton?: boolean // if set an "Add New Account" button will be added at the end of the list
  showAllAccounts?: boolean // if set, "All Accounts" option will be added at the beginning of the list
  selectedAccount?: AccountBalanceOut // selected account
  setSelectedAccount?: (account: AccountBalanceOut) => void // selected account setter
  hasExchangeAssets?: boolean
  className?: string
  showId?: boolean // shows account id next to name
  noBorder?: boolean // hides border
  noShadow?: boolean
  labelStyles?: any
  filteredCurrencies?: any[]
  loading?: boolean //todo make use of it
  onAddAccount?: (currency: string, accountId: number) => void
}

export const SelectAccount = (props: SelectAccountProps) => {
  const {
    staticDisplay = false, // if set, static account display will be presented
    accounts, // list of accounts to select from
    error, // error message (if needed)
    label, // label above the selection box
    showAddButton = false, // if set an "Add New Account" button will be added at the end of the list
    showAllAccounts = false, // if set, "All Account" option will be added at the beginning of the list
    selectedAccount, // selected account
    setSelectedAccount, // selected account setter
    filteredCurrencies, // list of supported currencies for the add new account screen
    noShadow = false,
    hasExchangeAssets = true,
    className,
    showId = false, // shows account id next to name
    noBorder = false, // hides border
    labelStyles,
    onAddAccount
  } = props

  const customerRole = useAppSelector(selectCurrentUserRole)

  const [filteredAccounts, setFilteredAccounts] = useState([])
  const [preselectAccountId, setPreselectAccountId] = useState(0)

  const handleCreateSuccess = (currency: string, accountId: unknown) => {
    if (typeof onAddAccount === 'function') {
      onAddAccount(currency, Number(accountId))
    } else {
      setPreselectAccountId(Number(accountId))
    }
  }

  const { visible, hideDropDown, toggleDropDown } = useDropDownMenu()

  const { modalShow } = useContext(GlobalContext)
  const toggle = () => !staticDisplay && toggleDropDown()

  const handleSelect = (account: AccountBalanceOut): void => {
    hideDropDown()
    setSelectedAccount(account)
  }

  const handleNewAccountClick = () => {
    modalShow({
      options: { closeOnClickOutside: false, size: 'auto', overflow: 'none' },
      content: <AccountCreateModal filteredCurrencies={filteredCurrencies} onSuccess={handleCreateSuccess} />
    })
  }

  const { balance, currencyCode, name, id } = selectedAccount || {}

  useEffect(() => {
    const list = Array.isArray(accounts) ? accounts : []
    setFilteredAccounts(list)

    if (preselectAccountId) {
      const find = list.find(account => account.id === preselectAccountId)
      setPreselectAccountId(0)
      handleSelect(find)
    }
    // eslint-disable-next-line
  }, [accounts])

  return (
    <SelectAccountWrapper className={cn('select-account-wrapper', className)} data-test='account-dropdown'>
      {label && <SelectAccountLabel style={labelStyles}>{label}</SelectAccountLabel>}
      <SelectAccountStyled>
        <AccountButtonStyled
          className={cn({ 'no-shadow': noShadow, static: staticDisplay, error: error?.length, 'no-border': noBorder })}
          onClick={toggle}>
          {selectedAccount ? (
            <span className='account-info'>
              <span className='icon'>
                <CurrencyIcon currency={currencyCode} width='20px' height='20px' circle />
              </span>
              <span className='content'>
                <span className='balance'>
                  {formatMoney(balance, currencyCode)}
                  {name ? ` (${name})` : ''} {showId && id ? ` - ID: ${id}` : ''}
                </span>
              </span>
            </span>
          ) : (
            <span className='no-account'>
              {showAllAccounts
                ? tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.ALL_ACCOUNTS', 'All Accounts')
                : tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.PLACEHOLDER', 'Select Account')}
            </span>
          )}
          {!staticDisplay && <span className='chevron'>{visible ? <ChevronUpIcon /> : <ChevronDownIcon />}</span>}
        </AccountButtonStyled>

        {!staticDisplay && (
          <AccountListStyled opened={visible} data-test='accounts-menu-content'>
            {showAllAccounts && (
              <AccountStyled data-test='all-accounts-menu-item' onClick={() => handleSelect(null)}>
                <span className='icon' />
                <span className='content'>
                  <span className='balance'>
                    {tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.ALL_ACCOUNTS', 'All Accounts')}
                  </span>
                </span>
              </AccountStyled>
            )}
            {!isValidArray(filteredAccounts) && (
              <AccountStyled data-test='all-accounts-menu-item' className='disabled'>
                <span className='icon'>
                  <span className='icon-all'>N/A</span>
                </span>
                <span className='content'>
                  <span className='balance'>
                    <b>
                      {hasExchangeAssets
                        ? tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.NO_ACCOUNTS', 'No Eligible Accounts')
                        : tr(
                            'FRONTEND.COMPONENTS.SELECT_ACCOUNT.CANNOT_EXCHANGE',
                            'Currently this asset cannot be exchanged'
                          )}
                    </b>
                  </span>
                </span>
              </AccountStyled>
            )}
            {filteredAccounts?.map(account => {
              const { id, currencyCode, balance, name: accName } = account

              return (
                <AccountStyled
                  key={id}
                  onClick={() => handleSelect(account)}
                  data-test='account-menu-item'
                  className='option'>
                  <span className='icon'>
                    <CurrencyIcon currency={currencyCode} width='20px' height='20px' circle />
                  </span>
                  <span className='content'>
                    <span className='balance'>
                      {formatMoney(balance, currencyCode)} {accName ? `(${accName})` : ''}
                    </span>
                  </span>
                </AccountStyled>
              )
            })}
            {showAddButton && isOwnerOrAdministrator(customerRole) && (
              <AddButtonWrapper>
                <Button
                  type='button'
                  color='round-secondary'
                  text={tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.ADD_BUTTON', 'Add New Account')}
                  onClick={handleNewAccountClick}
                />
              </AddButtonWrapper>
            )}
          </AccountListStyled>
        )}
      </SelectAccountStyled>

      {isValidString(error) && <SelectAccountError>{error}</SelectAccountError>}
    </SelectAccountWrapper>
  )
}
