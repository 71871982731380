import cn from 'classnames'

import { Button, ErrorDisplay, Spinner } from 'mmfintech-portal-commons'
import { Countdown, ResendMessage, VerificationContainer } from './Otp.styled'

import { tr, useValidUntilTimer, formatPhoneNumber } from 'mmfintech-commons'
import { globalSettings, selectCurrentUserStatus, useAppSelector, useOtpQry } from 'mmfintech-backend-api'

import { LoginStatusEnum, TwoFactorTypeEnum } from 'mmfintech-commons-types'

export const Otp = () => {
  const {
    code,
    codeSize,
    attempts,
    challenge,
    handleCodeChanged,
    handleCancel,
    handleResend,
    verifyOtpError,
    verifyOtpFetching,
    resendOtpFetching,
    resendTimer
  } = useOtpQry({
    autoSubmit: true
  })

  const userStatus = useAppSelector(selectCurrentUserStatus)

  const timer = useValidUntilTimer()

  const { sentTo, twoFactorType } = challenge || {}

  const prepareTitle = () => {
    if (twoFactorType === TwoFactorTypeEnum.SMS) {
      if (userStatus === LoginStatusEnum.LOGGED_IN) {
        return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_SMS', 'SMS Verification')
      }
      return tr('FRONTEND.VERIFY_OTP.TITLE_SMS', 'SMS Confirmation')
    }
    if (userStatus === LoginStatusEnum.SECOND_FACTOR || userStatus === LoginStatusEnum.LOGGED_IN) {
      return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_EMAIL', 'Email Verification')
    }
    return tr('FRONTEND.VERIFY_OTP.TITLE_EMAIL', 'Email Confirmation')
  }

  return (
    <VerificationContainer data-test='otp-verification-dialog'>
      <div className='inner'>
        <div className='title'>{prepareTitle()}</div>
        <div className='subtitle'>
          <div>
            {tr('FRONTEND.VERIFY_OTP.TARGET_LABEL', "Please, enter the verification code that we've sent to:")}
            <span>{twoFactorType === TwoFactorTypeEnum.SMS ? formatPhoneNumber(sentTo) : sentTo}</span>
          </div>
        </div>

        <ErrorDisplay error={verifyOtpError} />

        {verifyOtpFetching || resendOtpFetching ? (
          <Spinner />
        ) : (
          <>
            {attempts < globalSettings.otpMaxAttempts && (
              <Countdown isExpired={timer.expired} data-test='expire-time'>
                {timer.formattedTime}
              </Countdown>
            )}
            <input
              id='otp'
              type='tel'
              className={`${cn({
                complete: code?.length === codeSize,
                wrong: code?.length === codeSize && verifyOtpFetching != null
              })} input`}
              autoComplete='off'
              onChange={e => handleCodeChanged(e.target.value)}
              value={code}
              maxLength={codeSize}
              autoFocus
              placeholder={tr('FRONTEND.VERIFY_OTP.ENTER_CODE', 'Enter Code')}
              disabled={timer.expired || attempts >= globalSettings.otpMaxAttempts}
              data-test='tel-input'
            />
          </>
        )}

        <div className='buttons-wrap'>
          <Button
            type='button'
            color='alternative'
            text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            disabled={verifyOtpFetching || resendOtpFetching}
            onClick={handleCancel}
            data-test='button-cancel'
          />
        </div>
      </div>

      <ResendMessage>
        {attempts < globalSettings.otpMaxAttempts ? (
          <>
            {tr('FRONTEND.VERIFY_OTP.NOT_RECEIVED', "Haven't received it?")}
            <span onClick={handleResend} className={cn({ disabled: !resendTimer.expired })}>
              {resendTimer.expired ? tr('FRONTEND.VERIFY_OTP.RESEND_CODE', 'Re-send code') : resendTimer.remainingTime}
            </span>
          </>
        ) : (
          tr('FRONTEND.VERIFY_OTP.NO_MORE_ATTEMPTS', 'No more verification attempts.')
        )}
      </ResendMessage>
    </VerificationContainer>
  )
}
